<template lang="html">
    <div class="Pricing" id="pricing">
        <div class="SectionHeader">
            <p>
                Spezielle Pakete für Ihre Taxiflotte.
            </p>
            <h2 class="Headline">
                Flexible Lösungen
            </h2>
        </div>

        <p style="margin-top: 50px; font-size: 18px;">
            Einzelfahrerrabatt: 3€ pro Monat | Großkundenrabatt: ab 10 Autos 5% Rabatt, ab 15 Autos
            10% Rabatt.
            <br />
            Es fallen 49€ einmalige Einrichtungsgebühr an.
            <br />
            <br />
        </p>
        <div class="Price-Wrap">
            <!-- TaxiFusion Starter -->
            <Card class="Price-Card">
                <h3>
                    TaxiFusion Starter - 16€
                    <span class="Info">pro Monat / pro Taxi</span>
                </h3>
                <ul>
                    <li><span>Mitarbeiter und Fahrzeugverwaltung</span></li>
                    <li><span>Einzelfahrtenaufzeichnung</span></li>
                    <li><span>Schichtmanagement</span></li>
                    <li><span>Betriebsnachweise</span></li>
                    <li><span>Arbeitszeitnachweise</span></li>
                    <li>
                        <span>Integrierte taxiFusion Driver App</span>
                        <ul>
                            <li><span>Pausenzeiten</span></li>
                            <li><span>Fahrtmarkierungen und Anpassungen</span></li>
                            <li><span>Statistiken und Auswertungen</span></li>
                        </ul>
                    </li>
                    <li>
                        <span>SIM-Karte zur Datenübertragung von taxiFusion</span>
                        <ul>
                            <li><span>ohne Vertragsbindung</span></li>
                        </ul>
                    </li>
                </ul>
                <!-- <Button variant="" @onClick="() => this.$router.push('/sign-in')">
                    Wählen Sie Starter
                </Button> -->
            </Card>
            <div class="Modules">
                <!-- <h4>Zusatzfunktionen</h4> -->
                <p>
                    Wählen Sie die Zusatzfunktionen, die Ihr Geschäft benötigt.
                </p>
                <Card>
                    <h5>Performance-Analyse</h5>
                    <span class="Info">1€ pro Monat / pro Taxi</span>
                    <p>
                        Detaillierte Einblicke in die Leistung Ihrer Fahrer und Fahrzeuge für eine
                        optimierte betriebliche Effizienz.
                    </p>
                </Card>
                <Card>
                    <h5>Erinnerungsfunktionen</h5>
                    <span class="Info">3€ pro Monat / pro Taxi</span>
                    <p>
                        Erinnerungen für Reifen und Ölwechsel, Inspektionen, TÜV,
                        P-Schein-Verlängerung und mehr. Verpassen Sie nie wieder wichtige Termine.
                    </p>
                </Card>
            </div>

            <!-- <Card class="Price-Card ">
                <h3>
                    TaxiFusion<br />
                    Pro
                </h3>
                <ul>
                    <li><span>Alle Funktionen in TaxiFusion Starter</span></li>
                    <li>
                        <span>Performance-Analyse</span>
                        <p class="Description">
                            Übersicht der Leistung Ihrer Fahrer und Fahrzeuge für verbesserte
                            betriebliche Effizienz.
                        </p>
                    </li>
                    <li>
                        <span>Reminder-Funktionen</span>
                        <p class="Description">
                            Erinnerungen für Inspektionen, TÜV, P-Schein-Verlängerung und mehr.
                        </p>
                    </li>
                    <li>
                        <span>Fahrtenbuch</span>
                        <p class="Description">
                            Fahrtenbuch für die Finanzbehörde zur Reduzierung von
                            Verwaltungsaufwand.
                        </p>
                    </li>
                </ul>
                <h2 class="Price">
                    24,99€
                    <span class="Info">pro Monat / pro Taxi</span>
                </h2>

                <Button variant="basic" @onClick="() => this.$router.push('/sign-in')"
                    >Wählen Sie Pro</Button
                >
            </Card>

            <Card class="Price-Card Price-Card--highlight">
                <h3>
                    TaxiFusion <br />
                    Elite
                </h3>
                <ul>
                    <li><span>Alle Funktionen in TaxiFusion Pro</span></li>
                    <li>
                        <span>Werkstatt-Service</span>
                        <p class="Description">
                            Überblick über Reparaturen und Wartungen zur Reduzierung von
                            Ausfallzeiten und Verbesserung der Fahrzeuglebensdauer.
                        </p>
                    </li>

                    <li>
                        <span>Tracking der Fahrzeuge (Mit F4)</span>
                        <p class="Description">
                            Live-Tracking der Fahrzeuge für verbessertes Management und Effizienz.
                        </p>
                    </li>
                    <li>
                        <span>Kostenlose Bereitstellung von Daten</span>
                        <p class="Description">
                            Erfüllung der Mobilitätsdatenverordnung ohne zusätzliche Kosten.
                        </p>
                    </li>
                    <li>
                        <span>Fahrerabrechnung</span>
                        <p class="Description">
                            Vereinfachte und effiziente Abrechnungsprozesse sparen Zeit und
                            reduzieren Fehler.
                        </p>
                    </li>
                    <li>
                        <span>Rücklagenberechnung</span>
                        <p class="Description">
                            Finanzielle Planung und Budgetierung für Investitionen.
                        </p>
                    </li>
                </ul>
                <h2 class="Price">
                    29,99€
                    <span class="Info">pro Monat / pro Taxi</span>
                </h2>
                <Button variant="warning" @onClick="() => this.$router.push('/sign-in')">
                    Wählen Sie Elite</Button
                >
            </Card> -->
        </div>

        <!-- <h3 style="margin-top: 200px;">
            Sie haben eine große Flotte oder komplexe Anforderungen?
        </h3>
        <div class="Enterprise-Section">
            <div class="Enterprise-Content">
                <h3>TaxiFusion Enterprise</h3>
                <p class="Enterprise-Description">
                    Maßgeschneiderte Lösungen für große Flotten und komplexe Anforderungen.
                </p>
                <ul class="Enterprise-Features">
                    <li><span>Individuelle Konfiguration und Integration</span></li>
                    <li><span>Erweiterte Analyse und Berichterstattung</span></li>
                    <li><span>Dedizierter Account-Manager und Premium-Support</span></li>

                </ul>
            </div>
            <div class="Enterprise-Pricing">
                <div class="Price-Box">
                    <p class="Price-Tagline">Kontaktieren Sie uns für ein Angebot</p>
                    <h2 class="Price">Preis nach Absprache</h2>
                    <p class="Price-Info">
                        Ihre Individualität ist uns wichtig. Wir bieten Ihnen ein maßgeschneidertes
                        Angebot, das auf Ihre Bedürfnisse zugeschnitten ist.
                    </p>
                    <br />
                    <br />
                    <a href="#contact" style="text-decoration: none;">
                        <Button variant="basic">
                            Enterprise anfragen
                        </Button>
                    </a>
                </div>
            </div>
        </div> -->
        <p style="margin-top: 50px; font-size: 14px;">
            Alle Preise verstehen sich zzgl. MwSt.
        </p>
    </div>
</template>

<script>
import Card from '@/components/Card.vue';
// import Button from '@/components/widgets/Button.vue';

export default {
    components: {
        Card,
        // Button,
    },
};
</script>

<style lang="scss">
.Pricing {
    @extend %sectionWrapper;
    padding: 78px 75px 85px;
    hyphens: auto;
    position: relative;
    z-index: 1;

    @media (max-width: breakpoint(tabletPortrait)) {
        padding: 50px 20px;
    }

    h2,
    h3,
    h4 {
        font-weight: 700;
    }
    .SectionHeader {
        margin: 30px 0 50px;
        text-align: center;

        p {
            font-size: 1.125rem;
        }
    }
    .Headline {
        margin-bottom: 10px;
        font-weight: 700;

        @media (max-width: breakpoint(tabletPortrait)) {
            text-align: left;
        }

        span {
            font-weight: 700;
        }
    }
    h4 {
        margin-bottom: 20px;
    }

    ul {
        margin-left: 30px;
        margin-top: 40px;
        margin-bottom: 20px;
        padding: 0;
        line-height: 1.5;
        font-size: 16px;
        display: inline-block;
    }

    li {
        list-style-image: url('../../assets/svg/checkmark.svg');
        margin-bottom: 20px;

        span {
            transform: translateY(-4px);
            margin-left: 10px;
            font-size: 16px;
            font-weight: 400;
            display: block;
        }

        ul {
            margin-top: 5px;
            margin-bottom: 5px;
        }
        li {
            list-style: disc;
            margin-bottom: 0px;

            span {
                transform: translateY(0px);
                font-size: 14px;
            }
        }

        .Description {
            font-size: 12px;
            margin-left: 10px;
            margin-bottom: 10px;
        }
    }

    .Price-Wrap {
        display: flex;
        column-gap: 20px;
        justify-content: center;
        align-items: center;

        @media (max-width: breakpoint(tabletLandscape)) {
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: 30px;
        }
    }

    .Price-Card {
        padding: 20px 26px;
        flex: 1;
        justify-self: flex-start;
        border-radius: 10px;
        display: flex;
        flex-direction: column;

        h3 {
            font-size: 26px;
            margin-top: 0;
        }

        h4 {
            text-align: center;
        }
        @media (max-width: breakpoint(tabletPortrait)) {
            min-width: 100%;
            justify-self: center;
        }
    }

    .Price {
        margin: auto 0 40px;
        text-align: center;
        font-size: 36px;
    }

    .Info {
        font-size: 14px;
        font-weight: 700;
        display: block;
        margin-top: 0px;
        margin-bottom: 5px;
    }
    .Modules {
        display: flex;
        flex-direction: column;
        flex: 1;
        gap: 10px;

        h4 {
            font-weight: bold;
            font-size: 1.25rem;
            margin-bottom: 0;
        }

        h5 {
            font-weight: bold;
            font-size: 1.125rem;
            margin-bottom: 0;
        }

        p {
            font-size: 1rem;
            margin-bottom: 20px;
        }
    }
    .Price-Card--highlight {
        background-image: linear-gradient(0deg, #616c9f 0%, #09090c 98%);
        background: linear-gradient(0, #616c9f 3.27%, #222539 96.02%, #323043 143.5%);
        background: linear-gradient(-42deg, color(blue-light) 0%, color(blue-dark) 100%);
        box-shadow: 0px 5px 40px rgba(0, 0, 0, 0.1);
        margin-right: 0px;
        position: relative;
        z-index: 1;

        @media (max-width: breakpoint(tabletPortrait)) {
            padding: 40px;
            min-width: 100%;
            justify-self: center;
            margin-right: 0;
        }

        h3,
        p,
        .Price,
        li {
            color: white;
        }

        > span {
            color: white;
            font-size: 14px;
            display: block;
        }
    }
    .Button {
        width: 100%;
        text-align: center;
        margin-top: 80px;
        padding: 14px 25px;
        display: block !important;
    }
    a {
        @media screen and (max-width: breakpoint(mobile)) {
            text-decoration: underline;
        }
    }
    .Logo {
        display: block;
        width: 80px;
        margin: 0 auto;
    }

    .Enterprise-Section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 80px;
        padding: 20px;
        background: #ffffff; // This should be the background color of the section
        border-radius: 10px;
        border: 1px solid #e5e5e5; // This should be the border color of the section
        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);

        @media (max-width: breakpoint(tabletPortrait)) {
            flex-direction: column;
        }
    }

    .Enterprise-Content {
        flex: 1;
        padding-right: 20px; // Adjust as needed
        padding-left: 20px; // Adjust as needed

        h3 {
            margin-bottom: 20px;
            font-size: 24px;
        }

        .Enterprise-Description {
            font-size: 16px;
            margin-bottom: 30px;
            color: #333; // Adjust color as needed
        }

        .Enterprise-Features {
            margin-top: 0;

            li {
                span {
                    font-weight: 400;
                }
            }
        }
    }

    .Enterprise-Pricing {
        flex: 1;
        text-align: center; // Center align the pricing box

        .Price-Box {
            background: linear-gradient(-42deg, color(blue-light) 0%, color(blue-dark) 100%);
            padding: 40px;
            border-radius: 10px;
            box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);

            > * {
                color: white;
            }

            .Button {
                color: black;
            }

            .Price-Tagline {
                font-size: 14px;
                margin-bottom: 10px;
            }

            .Price {
                font-size: 36px;
                margin-bottom: 30px;
            }

            .Price-Info {
                font-size: 14px;
                margin-top: 20px;
            }
        }
    }
}
</style>
