<template>
    <div>
        <div class="Contact-Wrap">
            <div class="Contact" id="contact">
                <div class="SectionHeader">
                    <p>Immer für Sie da</p>
                    <h2>Kontaktieren Sie uns</h2>
                </div>
                <p>
                    Wir freuen uns auf Ihre Nachricht und werden uns schnellstmöglich bei Ihnen
                    melden.
                </p>
                <form @submit.prevent="submitForm" class="ContactForm">
                    <div class="FormRow">
                        <div class="InputWrap">
                            <label for="name">Name</label>
                            <input
                                class="Input"
                                type="text"
                                id="name"
                                v-model="form.name"
                                required
                            />
                        </div>
                        <div class="InputWrap">
                            <label for="email">Email Adresse</label>
                            <input
                                class="Input"
                                type="email"
                                id="email"
                                v-model="form.email"
                                required
                            />
                            <span class="Hint">Damit wir Ihnen antworten können.</span>
                        </div>

                        <div class="InputWrap">
                            <label for="message">Nachricht</label>
                            <textarea
                                class="Input"
                                id="message"
                                v-model="form.message"
                                required
                            ></textarea>
                            <span class="Hint">Wie können wir Ihnen helfen?</span>
                        </div>
                    </div>
                    <button type="submit" class="SubmitButton">Nachricht senden</button>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            form: {
                name: '',
                cars: 1,
                email: '',
                message: '',
            },
        };
    },
    methods: {
        submitForm() {
            // Handle the form submission, like sending data to a server
        },
    },
};
</script>

<style lang="scss">
.Contact-Wrap {
    padding: 40px;
    background-color: white;

    .Contact {
        border-radius: 10px;
        max-width: 800px;
        margin: 80px auto;

        .SectionHeader {
            margin: 30px 0 80px;
            text-align: center;

            p {
                font-size: 1.125rem;
            }
        }

        h2 {
            font-weight: bold;
            margin: 0px;
        }

        .ContactForm {
            margin-top: 30px;
            display: flex;
            flex-direction: column;
            gap: 40px;

            @media (min-width: 600px) {
            }

            .FormRow {
                display: flex;
                flex-direction: column;
                gap: 20px;
            }

            .InputWrap {
                display: flex;
                flex-direction: column;
                label {
                    font-weight: bold;
                    margin-bottom: 5px;
                    line-height: 1.5;
                }
                .Input,
                textarea {
                    padding: 10px;
                    border-radius: 5px;
                    border: 1px solid #ddd;
                    background: white;

                    &:focus {
                        outline: none;
                        border-color: color(blue-light);
                    }
                }
                textarea {
                    min-height: 100px;
                }
            }
        }
        .Hint {
            font-size: 12px;
            color: #666;
            margin-top: 12px;
        }
        .SubmitButton {
            background-color: color(blue-light);
            color: white;
            border: none;
            padding: 15px;
            font-size: 16px;
            font-weight: bold;
            border-radius: 5px;
            cursor: pointer;
            transition: background-color 0.2s;

            &:hover {
                background-color: color(blue-dark);
            }
            @media (min-width: 600px) {
                grid-column: span 2;
            }
        }
    }
}
</style>
