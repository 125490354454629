<template>
    <div id="app">
        <transition name="fade" as="div">
            <router-view />
        </transition>
        <portal-target multiple name="default" />
        <portal-target multiple name="dropdown" id="dropdown" />
    </div>
</template>
<script>
import axios from 'axios';
import 'remixicon/fonts/remixicon.css';

export default {
    name: 'app',
    methods: {
        async handleLogoutClick(e) {
            e.preventDefault();

            sessionStorage.clear();
            localStorage.clear();

            await axios.get(`${process.env.VUE_APP_API_BASE_URL}/logout`, {
                withCredentials: true,
            });
            this.$router.push('/');
        },
    },
    watch: {
        $route: {
            async handler(from, to) {
                const isHomePage = this.$route.path === '/';
                const isSignInPage = this.$route.path === '/sign-in';
                const isRegisterPage = this.$route.path === '/register';
                try {
                    const result = await axios.get(
                        `${process.env.VUE_APP_API_BASE_URL}/cpanel/profile/simple`,
                        {
                            withCredentials: true,
                        },
                    );
                    if (isHomePage || isSignInPage || isRegisterPage) {
                        this.$router.push(to);
                    }
                } catch (error) {
                    if (isHomePage || isSignInPage || isRegisterPage) {
                        return;
                    }

                    this.$router.push('/sign-in');
                }
            },
            immediate: true,
        },
    },
};
</script>
<style lang="scss">
@import './styles/styles.scss';
/* open-sans-300 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: url('./assets/fonts/open-sans-v40-latin-300.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* open-sans-regular - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/open-sans-v40-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* open-sans-700 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: url('./assets/fonts/open-sans-v40-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

#app {
    .fade-enter-active,
    .fade-leave-active {
        transition-duration: 0.3s;
        transition-property: opacity;
        transition-timing-function: ease-in-out;
    }
    .fade-enter,
    .fade-leave-active {
        opacity: 0;
    }
}

/* total width */
body::-webkit-scrollbar {
    background-color: var(--color-white);
    width: 16px;
}

/* background of the scrollbar except button or resizer */
body::-webkit-scrollbar-track {
    background-color: var(--color-white);
}

/* scrollbar itself */
body::-webkit-scrollbar-thumb {
    background-color: var(--color-grey);
    border-radius: 16px;
    border: 4px solid var(--color-white);
}

/* set button(top and bottom of the scrollbar) */
body::-webkit-scrollbar-button {
    display: none;
}
</style>
