<template>
    <div
        class="InputWrap"
        :class="[
            { 'is-disabled': isDisabled },
            { 'is-checkbox': type === 'checkbox' },
            { 'is-password': type === 'password' },
            { 'has-error': error && error.length > 0 },
            `is-${direction}`,
        ]"
        :style="style"
    >
        <label class="Label" :for="label">{{ label }}</label>
        <input
            v-if="type === 'checkbox'"
            class="Input"
            type="checkbox"
            :name="label"
            :disabled="isDisabled"
            :checked="value"
        />
        <select
            v-else-if="type === 'select'"
            class="Input"
            v-model="mValue"
            :name="label"
            :disabled="isDisabled"
        >
            <option v-for="option in options" :key="option.value" :value="option.value">{{
                option.label
            }}</option>
        </select>

        <input
            v-else
            class="Input"
            v-model="mValue"
            :type="type"
            :pattern="pattern"
            :min="min"
            :max="max"
            :name="label"
            :disabled="isDisabled"
            :placeholder="placeholder"
            @keyup.enter="$emit('onEnterPress')"
            ref="input"
        />

        <span v-if="error && error.length > 0" class="Error">{{ error }}</span>
    </div>
</template>
<script>
export default {
    name: 'Input',
    props: {
        options: {
            type: Array,
            default: () => [],
        },
        pattern: {
            type: String,
            default: null,
        },
        label: {
            type: String,
            default: '',
        },
        keyName: {
            type: String,
            default: null,
        },
        type: {
            type: String,
            default: 'text',
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        value: {
            type: [String, Number],
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        error: {
            type: String,
            default: '',
        },
        direction: {
            type: String,
            default: 'vertical',
        },
        marginBottom: {
            type: Number,
            default: 0,
        },
        marginTop: {
            type: Number,
            default: 0,
        },
        min: {
            type: [Number, String],
            default: 0,
        },
        max: {
            type: [Number, String],
            default: Math.pow(2, 31) - 1,
        },
    },
    computed: {
        style: function() {
            const style = {
                marginBottom: `${this.marginBottom * 5}px`,
                marginTop: `${this.marginTop * 5}px`,
            };
            return style;
        },
        mValue: {
            get() {
                if (this.type === 'date') {
                    const dateWithoutTime = this.value.split('T')[0];
                    return dateWithoutTime
                        ? new Date(dateWithoutTime).toISOString().substring(0, 10)
                        : '';
                }
                return this.value;
            },
            set(value) {
                // check if keyName is already in camelCase
                // this.keyName can have these formats: keyName, key-name, key_name, key name
                const givenKeyName = this.keyName || this.label;
                const isCamelCase = (givenKeyName || '').match(/[a-z]+/g)?.length > 1;
                let key = isCamelCase ? givenKeyName : givenKeyName.toLowerCase();
                key = key.replace(/ ([a-z])/g, (m, w) => w.toUpperCase());
                if (this.type === 'number') {
                    value = Number(value);
                }
                this.$emit('onKeyPress', { [key]: value });
            },
        },
    },
};
</script>
<style lang="scss">
.InputWrap {
    position: relative;
    width: 100%;

    &.has-error {
        .Input,
        label {
            border-color: color(red) !important;
            color: color(red) !important;
        }
    }

    &.is-vertical {
        .Label {
            position: relative;
            top: 0px;
            left: 10px;
            transition: all 0.2s ease-out;
            font-size: rem(14px);
            transform: translateY(-35px);
        }
        .Input {
            padding: 12px 30px 12px 12px;

            ::placeholder {
                color: transparent;
            }

            &:focus,
            &:valid {
                + .Label {
                    transform: translateY(-30px);
                    font-size: rem(12px);
                }
            }
        }
    }

    &.is-horizontal {
        display: flex;
        align-items: center;
        .Label {
            transition: all 0.2s ease-out;
            font-size: 1rem;
            flex: unset;
            width: auto;
            min-width: 30%;
            &::after {
                content: ':';
            }
        }
        .Input {
            flex: 1;
        }

        @media screen and (max-width: breakpoint(tabletPortrait)) {
            flex-direction: column;
            align-items: flex-start;

            &.is-disabled {
                .Input {
                    padding-left: 0;
                }
            }
            .Input {
                width: 100%;
            }
        }
    }

    &.is-checkbox {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: center;

        .Label {
            flex: unset;
            width: auto;
            transform: unset;
            margin-right: 20px;
        }
        .Input {
            width: 20px;
            height: 20px;
            margin: 0;
            flex: unset;
        }
    }

    &.is-disabled {
        .Input {
            color: var(--color-text-gray-dark);
            background-color: transparent;
        }
    }

    &.is-password {
        .Input {
        }
    }

    &.show-password {
        circle {
            opacity: 1;
            transition: all 0s;
            transform: translateY(-18px);
            transition-delay: 0.1s;
        }

        .EyeLash {
            transform: scaleY(-1) translateY(-12px);
        }
    }

    .Label,
    .Input {
        flex: 1;
    }

    input[type='date']::-webkit-calendar-picker-indicator {
        filter: invert(0.8);
    }
    .Label {
        color: var(--color-text-black);
    }

    .Input {
        outline: none;
        margin: 0;
        padding: 0;
        background-color: transparent;
        border: none;
        font-family: font(regular);
        width: 100%;
        padding: 12px 30px 12px 22px;
        font-size: 1rem;
        background-color: var(--color-bg);
        color: var(--color-text-black);
        border-radius: 5px;

        &::placeholder {
            color: var(--color-text-gray-dark);
        }

        &:focus {
            + .Label {
                transform: translateY(-30px);
                font-size: rem(12px);
            }
        }
    }

    .IconEye {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-20%);
        color: currentColor;
        cursor: pointer;
    }

    circle {
        opacity: 0;
        transition-delay: 0s;
    }

    .Error {
        color: color(red);
        margin-top: 5px;
        font-size: 12px;
        position: absolute;
        bottom: -23px;
        left: 12px;
    }

    .EyeLash {
        transition: all 0.3s;
        transform: scaleY(1) translateY(-15px);
    }
}
</style>
