var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "Contact-Wrap"
  }, [_c('div', {
    staticClass: "Contact",
    attrs: {
      "id": "contact"
    }
  }, [_vm._m(0), _c('p', [_vm._v(" Wir freuen uns auf Ihre Nachricht und werden uns schnellstmöglich bei Ihnen melden. ")]), _c('form', {
    staticClass: "ContactForm",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submitForm.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "FormRow"
  }, [_c('div', {
    staticClass: "InputWrap"
  }, [_c('label', {
    attrs: {
      "for": "name"
    }
  }, [_vm._v("Name")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.name,
      expression: "form.name"
    }],
    staticClass: "Input",
    attrs: {
      "type": "text",
      "id": "name",
      "required": ""
    },
    domProps: {
      "value": _vm.form.name
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "name", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "InputWrap"
  }, [_c('label', {
    attrs: {
      "for": "email"
    }
  }, [_vm._v("Email Adresse")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.email,
      expression: "form.email"
    }],
    staticClass: "Input",
    attrs: {
      "type": "email",
      "id": "email",
      "required": ""
    },
    domProps: {
      "value": _vm.form.email
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "email", $event.target.value);
      }
    }
  }), _c('span', {
    staticClass: "Hint"
  }, [_vm._v("Damit wir Ihnen antworten können.")])]), _c('div', {
    staticClass: "InputWrap"
  }, [_c('label', {
    attrs: {
      "for": "message"
    }
  }, [_vm._v("Nachricht")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.message,
      expression: "form.message"
    }],
    staticClass: "Input",
    attrs: {
      "id": "message",
      "required": ""
    },
    domProps: {
      "value": _vm.form.message
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "message", $event.target.value);
      }
    }
  }), _c('span', {
    staticClass: "Hint"
  }, [_vm._v("Wie können wir Ihnen helfen?")])])]), _c('button', {
    staticClass: "SubmitButton",
    attrs: {
      "type": "submit"
    }
  }, [_vm._v("Nachricht senden")])])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "SectionHeader"
  }, [_c('p', [_vm._v("Immer für Sie da")]), _c('h2', [_vm._v("Kontaktieren Sie uns")])]);
}]

export { render, staticRenderFns }