// create a vue store
import Vue from 'vue';
import Vuex from 'vuex';
import { state, mutations, actions, getters } from './mutations.js';

Vue.use(Vuex);

export default new Vuex.Store({
    state,
    mutations,
    actions,
    getters,
});
