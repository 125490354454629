<template>
    <div
        class="Spinner"
        :class="[
            { 'is-small': size === 'small' },
            { 'is-medium': size === 'medium' },
            { 'is-large': size === 'large' },
        ]"
    >
        <IconSpinner />
    </div>
</template>
<script>
import IconSpinner from '@/components/icons/IconSpinner';

export default {
    props: {
        size: {
            default: 'small',
            type: String,
        },
    },
    components: {
        IconSpinner,
    },
};
</script>
<style lang="scss" scoped>
.Spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    &.is-small {
        svg {
            height: 1rem;
            width: 1rem;
        }
    }
    &.is-medium {
        svg {
            height: 2rem;
            width: 2em;
        }
    }
    &.is-large {
        svg {
            height: 4rem;
            width: 4rem;
        }
    }
    svg {
        animation: spinner 1.2s cubic-bezier(0.14, 0.67, 1, 0.21) infinite;
    }
    @keyframes spinner {
        to {
            transform: rotate(360deg);
        }
    }
}
</style>
