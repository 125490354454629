import Vue from 'vue';
import Router from 'vue-router';
import Login from './views/Login.vue';
import Impressum from './views/Impressum.vue';
import Register from './views/Register.vue';
import Datenschutzerklaerung from './views/Datenschutzerklaerung.vue';
import Index from './views/';
import Error from './views/404.vue';

Vue.use(Router);

export default new Router({
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        return new Promise(resolve => {
            if (savedPosition) {
                return savedPosition;
            }
            setTimeout(() => {
                resolve({ x: 0, y: 0 });
            }, 500);
        });
    },
    routes: [
        {
            path: '/landing',
            name: 'landing',
            component: Index,
            meta: {
                transitionName: 'slide',
            },
        },
        {
            path: '/',
            name: 'landingPage',
            component: Login,
            meta: {
                transitionName: 'slide',
            },
        },
        {
            path: '/register',
            name: 'register',
            component: Register,
            meta: {
                transitionName: 'slide',
            },
        },
        {
            path: '/impressum',
            name: 'impressum',
            component: Impressum,
            meta: {
                transitionName: 'slide',
            },
        },
        {
            path: '/datenschutz',
            name: 'datenschutz',
            component: Datenschutzerklaerung,
            meta: {
                transitionName: 'slide',
            },
        },
        {
            path: '/sign-in',
            name: 'login',
            component: Login,
            meta: {
                transitionName: 'slide',
            },
        },
        {
            path: '/dashboard',
            name: 'Dashboard',
            component: () => import('./views/Dashboard/'),
            meta: {
                transitionName: 'slide',
            },
            children: [
                {
                    path: '/',
                    name: 'overview',
                    component: () => import('./views/Dashboard/Overview'),
                    meta: {
                        transitionName: 'slide',
                    },
                },
                {
                    path: '/revenue',
                    name: 'revenue',
                    component: () => import('./views/Dashboard/Revenue'),
                    meta: {
                        transitionName: 'slide',
                    },
                },
                {
                    path: '/analytics',
                    name: 'analytics',
                    component: () => import('./views/Dashboard/Analytics'),
                    meta: {
                        transitionName: 'slide',
                    },
                },
                {
                    path: '/working-time',
                    name: 'working-time',
                    component: () => import('./views/Dashboard/Workingtime'),
                    meta: {
                        transitionName: 'slide',
                    },
                },
                {
                    path: '/shifts',
                    name: 'shifts',
                    component: () => import('./views/Dashboard/Shifts/'),
                    meta: {
                        transitionName: 'slide',
                    },
                },
                {
                    path: '/trips',
                    name: 'trips',
                    component: () => import('./views/Dashboard/Trips/'),
                    meta: {
                        transitionName: 'slide',
                    },
                },
                {
                    path: '/employees',
                    name: 'employees',
                    component: () => import('./views/Dashboard/Employees'),
                    meta: {
                        transitionName: 'slide',
                    },
                },
                {
                    path: '/schedular',
                    name: 'schedular',
                    component: () => import('./views/Dashboard/Schedular'),
                    meta: {
                        transitionName: 'slide',
                    },
                },
                {
                    path: '/cars',
                    name: 'cars',
                    component: () => import('./views/Dashboard/Cars'),
                    meta: {
                        transitionName: 'slide',
                    },
                },
                {
                    path: '/cars/:id',
                    name: 'car',
                    component: () => import('./views/Dashboard/Cars/Car'),
                },
                {
                    path: '/driversLog',
                    name: 'driversLog',
                    component: () => import('./views/Dashboard/DriversLog'),
                    meta: {
                        transitionName: 'slide',
                    },
                },
                {
                    path: '/operationride',
                    name: 'operationride',
                    component: () => import('./views/Dashboard/Operationride'),
                    meta: {
                        transitionName: 'slide',
                    },
                },
                {
                    path: '/invoice',
                    name: 'invoice',
                    component: () => import('./views/Dashboard/Invoice'),
                    meta: {
                        transitionName: 'slide',
                    },
                },
                {
                    path: '/settings',
                    name: 'settings',
                    component: () => import('./views/Dashboard/Settings'),
                    meta: {
                        transitionName: 'slide',
                    },
                },
                {
                    path: '/faq',
                    name: 'faq',
                    component: () => import('./views/Dashboard/Faq'),
                    meta: {
                        transitionName: 'slide',
                    },
                },
                {
                    path: '/upgrade',
                    name: 'upgrade',
                    component: () => import('./views/Dashboard/Upgrade'),
                    meta: {
                        transitionName: 'slide',
                    },
                },
                {
                    path: '/savings-calculator',
                    name: 'savings-calculator',
                    component: () => import('./views/Dashboard/SavingsCalculator'),
                    meta: {
                        transitionName: 'slide',
                    },
                },
                {
                    path: '/savings-calculator:id',
                    name: 'employee-revenue',
                    component: () => import('./views/Dashboard/SavingsCalculator/Employee'),
                },
            ],
        },
        {
            path: '/404',
            name: '404',
            component: Error,
            meta: {
                transitionName: 'slide',
            },
        },
        {
            path: '*',
            redirect: '/404',
        },
    ],
});
