var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    staticClass: "Button",
    class: ["is-".concat(_vm.variant), {
      'is-disabled': _vm.isDisabled
    }, {
      'has-button': _vm.hasIcon
    }, {
      'is-large': _vm.size === 'large'
    }, {
      'is-small': _vm.size === 'small'
    }, {
      'is-loading': _vm.isLoading
    }],
    style: _vm.style,
    attrs: {
      "tabindex": "0",
      "disabled": _vm.isDisabled
    },
    on: {
      "click": _vm.handleClick
    }
  }, [_c('div', {
    staticClass: "Button-Wrapper"
  }, [_vm.isLoading ? _c('Spinner') : _vm._e(), _vm.hasIcon ? _c('span', {
    ref: "iconBefore",
    staticClass: "IconBefore"
  }, [_vm._t("iconBefore")], 2) : _vm._e(), _c('span', {
    staticClass: "Label"
  }, [_vm.value ? _c('span', [_vm._v(_vm._s(_vm.value))]) : _vm._e(), _vm._t("default")], 2)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }