var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    staticClass: "Logo",
    attrs: {
      "href": "/"
    }
  }, [_c('img', {
    attrs: {
      "src": require("../assets/svg/logo.svg")
    }
  })]);
}]

export { render, staticRenderFns }