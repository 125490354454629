<template>
    <a href="/" class="Logo"><img src="../assets/svg/logo.svg"/></a>
</template>

<script>
export default {
    name: 'Logo',
};
</script>

<style lang="scss" scoped>
.Logo {
    width: 70px;
    position: relative;
    z-index: 2;
    display: block;

    img {
        width: 100%;
    }
}
</style>
