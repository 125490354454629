var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "app"
    }
  }, [_c('transition', {
    attrs: {
      "name": "fade",
      "as": "div"
    }
  }, [_c('router-view')], 1), _c('portal-target', {
    attrs: {
      "multiple": "",
      "name": "default"
    }
  }), _c('portal-target', {
    attrs: {
      "multiple": "",
      "name": "dropdown",
      "id": "dropdown"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }