<template>
    <div class="ErrorPage">
        <nav class="Navbar">
            <Logo />
            <router-link to="sign-in">
                <SiteButton content="Login" />
            </router-link>
        </nav>
        <img src="404.jpg" alt="404" class="Error-image" />

        <div class="Error-404">
            <div class="Error-404-content">
                <h1>Ooops!</h1>

                <p>
                    Die Seite konnte nicht gefunden werden. <br />
                    Bitte überprüfen Sie die URL oder kehren Sie zur
                    <router-link to="/">Startseite</router-link> zurück.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import Logo from '@/components/Logo.vue';
import SiteButton from '@/components/SiteButton.vue';
export default {
    name: 'Error404',
    components: {
        Logo,
        SiteButton,
    },
};
</script>

<style scoped lang="scss">
.ErrorPage {
    overflow: hidden;
    padding: 20px;
    @extend %contentWrapper;
    @extend %page;
    background: linear-gradient(-42deg, color(blue-light) 0%, color(blue-dark) 100%);
    height: 100vh;

    .Navbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0;
        position: relative;
        z-index: 2;
        max-width: 1200px;
        margin: 0 auto;
    }

    .Error-404 {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 2;
    }
    .Error-404-content {
        text-align: center;
        width: 100%;
        height: 500px;
        margin-bottom: 20px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        z-index: 2;
        color: white;
        line-height: 1.75;
        padding: 0 20px;

        @media (max-width: 768px) {
            text-align: left;
        }

        h1 {
            color: white;
            font-size: 5rem;
            font-weight: 700;
            text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);

            @media (max-width: 768px) {
                font-size: 4rem;
            }
        }

        p {
            font-size: 1rem;
            font-weight: 400;
            margin-bottom: 10px;
        }

        a {
            color: var(--color-primary);
            text-decoration: underline;
            font-weight: 700;
        }
    }

    .Error-image {
        width: 100%;
        display: none;
        max-width: 1200px;
        margin: 40px auto;
        height: 300px;
        border-radius: 10px;
        object-fit: cover;
        object-position: bottom;
        box-shadow: 0 30px 40px rgba(0, 0, 0, 0.1);
        border: 1px solid rgba(0, 0, 0, 0.1);
    }
}
</style>
