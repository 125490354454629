var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "SiteHeader"
  }, [_vm._m(0), _c('nav', {
    staticClass: "Navigation"
  }, [_c('a', {
    staticClass: "SiteNavigation isActive",
    attrs: {
      "href": "#"
    }
  }, [_vm._v("taxifusion")]), _c('a', {
    staticClass: "SiteNavigation",
    attrs: {
      "href": "#features"
    }
  }, [_vm._v("features")]), _c('a', {
    staticClass: "SiteNavigation",
    attrs: {
      "href": "#pricing"
    }
  }, [_vm._v("preise")]), _c('a', {
    staticClass: "SiteNavigation",
    attrs: {
      "href": "#contact"
    }
  }, [_vm._v("kontakt")]), _c('router-link', {
    staticClass: "SiteNavigation",
    attrs: {
      "to": "register"
    }
  }, [_vm._v("Registrieren")]), _c('router-link', {
    attrs: {
      "to": "sign-in"
    }
  }, [_c('SiteButton', {
    attrs: {
      "content": "Login"
    }
  })], 1)], 1)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    staticClass: "Logo",
    attrs: {
      "href": "/"
    }
  }, [_c('img', {
    attrs: {
      "src": require("../assets/svg/logo.svg")
    }
  })]);
}]

export { render, staticRenderFns }