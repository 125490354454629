var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "About"
  }, [_c('h2', [_vm._v("Warum TaxiFusion?")]), _c('p', [_c('b', [_vm._v("taxiFusion")]), _vm._v(" ist mehr als nur eine Verpflichtung zur Datenspeicherung. "), _c('br'), _c('br'), _vm._v(" Unser Ziel ist es, Ihnen die Werkzeuge an die Hand zu geben, die Sie benötigen, um Ihr Geschäft effizienter und rentabler zu gestalten. Mit unserer benutzerfreundlichen Software sparen Sie wertvolle Zeit und Ressourcen, die Sie in den Ausbau Ihres Unternehmens investieren können. "), _c('br'), _c('br'), _vm._v(" Es geht um die effiziente Verwaltung Ihres ganzen Unternehmens mit einer einzigen benutzerfreundlichen Plattform. "), _c('br')])]);
}]

export { render, staticRenderFns }