var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Features",
    attrs: {
      "id": "features"
    }
  }, [_vm._m(0), _c('div', {
    staticClass: "Content"
  }, [_c('div', {
    staticClass: "Feature"
  }, [_c('div', {
    staticClass: "Text"
  }, [_c('h3', [_vm._v("Die Taxisoftware von morgen")]), _c('div', {
    staticClass: "VideoWrap"
  }, [_c('video', {
    staticClass: "Video",
    attrs: {
      "autoplay": "",
      "muted": "",
      "controls": "",
      "playsinline": "",
      "poster": "/product/dashboard.jpg",
      "speed": "0.5"
    },
    domProps: {
      "muted": true
    }
  }, [_c('source', {
    attrs: {
      "src": "/product/tf-demo.mp4",
      "type": "video/mp4"
    }
  })])]), _vm._m(1)])]), _vm._m(2), _vm._m(3), _vm._m(4), _c('div', {
    staticClass: "Feature Feature--App"
  }, [_c('img', {
    attrs: {
      "src": "/product/mobile.png",
      "alt": ""
    }
  }), _c('div', {
    staticClass: "Text"
  }, [_c('h3', [_vm._v("Die App für den Fahrer")]), _c('p', [_vm._v(" Die Fahrer-App ermöglicht es den Fahrern, auf wichtige Informationen zuzugreifen und ihre Fahrten effizient zu verwalten. Mit Echtzeit-Statistiken und Zugriff auf Betriebsdaten können Fahrer ihre Leistung verbessern. Die App ist benutzerfreundlich gestaltet und bietet den Fahrern eine nahtlose Integration in das Gesamtsystem. ")]), _c('div', {
    staticClass: "AppStoreIcons"
  }, [_c('a', {
    ref: "noopener noreferrer",
    attrs: {
      "href": "https://apps.apple.com/de/app/taxifusion-driver-app/id6523420111?l=en-GB",
      "target": "_blank"
    }
  }, [_c('img', {
    staticStyle: {
      "border": "1px solid white",
      "border-radius": "10px"
    },
    attrs: {
      "src": "/app-store-icon.png",
      "alt": "Apple App Store",
      "height": "50"
    }
  })]), _c('a', {
    ref: "noopener noreferrer",
    attrs: {
      "href": "https://play.google.com/store/apps/details?id=com.taxifusion.driverApp",
      "target": "_blank"
    }
  }, [_c('img', {
    staticStyle: {
      "border": "1px solid white",
      "border-radius": "10px"
    },
    attrs: {
      "src": "/google-play-icon.png",
      "alt": "Google Play Store",
      "height": "50"
    }
  })])])])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h2', [_vm._v("Wir "), _c('span', [_vm._v("vereinfachen")]), _vm._v(" alle Ihre Aufgaben")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_vm._v(" Umfassende Managementlösung, die speziell für die Anforderungen und Abläufe von Taxiunternehmen entwickelt wurde. Durch die intuitive Benutzeroberfläche und leistungsfähige Analysewerkzeuge können Sie Ihren Betrieb effizienter und transparenter gestalten. "), _c('br'), _c('br'), _vm._v(" Die Plattform integriert alle notwendigen Funktionen, von der Fahrzeugverfolgung bis hin zur Umsatzanalyse, in einem einzigen, leicht zugänglichen System. ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Feature"
  }, [_c('div', {
    staticClass: "Text"
  }, [_c('h3', [_vm._v("Überall zugänglich")]), _c('img', {
    attrs: {
      "src": "/product/revenue.png",
      "alt": ""
    }
  }), _c('p', [_c('b', [_vm._v("taxiFusion")]), _vm._v(" nutzt die Kraft der Cloud-Technologie, um eine zuverlässige, internetbasierte Verwaltungslösung anzubieten. Mit dieser Plattform können Sie von überall aus auf Ihr Geschäft zugreifen, am PC oder vom Handy aus, was maximale Flexibilität und Kontrolle ermöglicht. "), _c('br'), _c('br'), _vm._v(" Die Sicherheit Ihrer Daten wird durch moderne Verschlüsselungstechniken gewährleistet, während Sie gleichzeitig von der ständigen Verfügbarkeit und Aktualität der Software profitieren. ")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Feature"
  }, [_c('div', {
    staticClass: "Text"
  }, [_c('h3', [_vm._v("Einhaltung gesetzlicher Anforderungen")]), _c('img', {
    attrs: {
      "src": "/product/worktime.png",
      "alt": ""
    }
  }), _c('p', [_vm._v(" Wir sorgen dafür, dass Ihr Taxiunternehmen alle gesetzlichen Anforderungen erfüllt, was die administrative Last erheblich reduziert. "), _c('br'), _c('br'), _vm._v(" Die Plattform unterstützt Sie bei der Einhaltung von Vorschriften, sei es bei der Konzessionsverwaltung oder der Sicherstellung der Datenschutzstandards. Mit uns können Sie sich darauf verlassen, dass Ihr Betrieb stets den neuesten gesetzlichen Richtlinien entspricht. ")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Feature"
  }, [_c('div', {
    staticClass: "Text"
  }, [_c('h3', [_vm._v("Über 30 Jahre Erfahrung")]), _c('img', {
    attrs: {
      "src": "/product/shifts.png",
      "alt": ""
    }
  }), _c('p', [_vm._v(" Das System wurde mit direktem Input und Erfahrungen von Taxiunternehmern und -fahrern entwickelt, um sicherzustellen, dass es den branchenspezifischen Bedürfnissen entspricht. "), _c('br'), _c('br'), _vm._v(" Die Features und Funktionen sind auf die einzigartigen Herausforderungen und Chancen im Taxigewerbe zugeschnitten. ")])])]);
}]

export { render, staticRenderFns }