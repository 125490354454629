<template>
    <div class="TestimonialsContainer">
        <div class="TestimonialsHeading">
            <p>Wir haben mit hunderten von Kunden gearbeitet.</p>
            <h2>
                Was unsere Kunden über uns sagen.
            </h2>
        </div>
        <div class="TestimonialsContent">
            <!-- Testimonial Item -->
            <div class="TestimonialItem">
                <blockquote>
                    "taxiFusion.de hat unsere Effizienz gesteigert. Die Plattform ist intuitiv und
                    vereinfacht die Fahrtenverwaltung. Unsere Fahrer sind begeistert von der
                    nutzerfreundlichen App."
                </blockquote>
                <div class="TestimonialAuthor">
                    <img
                        src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=1024&h=1024&q=80"
                        alt="Julia Schmidt"
                    />
                    <div>
                        <div class="AuthorName">Julia Schmidt</div>
                    </div>
                </div>
            </div>

            <div class="TestimonialItem">
                <blockquote>
                    "Mit taxiFusion.de ist die Verwaltung unserer Flotte spielend leicht. Es bietet
                    alles, was wir brauchen, in einem System."
                </blockquote>
                <div class="TestimonialAuthor">
                    <img
                        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt="Michael Bauer"
                    />
                    <div>
                        <div class="AuthorName">Michael Bauer</div>
                    </div>
                </div>
            </div>

            <div class="TestimonialItem">
                <blockquote>
                    "Die cloud-basierte Lösung von taxiFusion.de ermöglicht es uns, unser Geschäft
                    flexibel zu steuern. Daten sind sicher und immer aktuell."
                </blockquote>
                <div class="TestimonialAuthor">
                    <img
                        src="https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt="Andreas Müller"
                    />
                    <div>
                        <div class="AuthorName">Andreas Müller</div>
                    </div>
                </div>
            </div>

            <div class="TestimonialItem">
                <blockquote>
                    "taxiFusion.de unterstützt uns bei der Einhaltung gesetzlicher Anforderungen und
                    vereinfacht unsere Arbeit erheblich."
                </blockquote>
                <div class="TestimonialAuthor">
                    <img
                        src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt="Sarah Weber"
                    />
                    <div>
                        <div class="AuthorName">Sarah Weber</div>
                    </div>
                </div>
            </div>

            <!-- More testimonials... -->
        </div>
    </div>
</template>

<script>
export default {
    name: 'SiteTestimonials',
    props: {
        title: String,
        text: String,
        cancel: {},
        action: {},
        size: {
            type: String,
            default: 'small',
        },
    },
    data: () => ({
        actionTextDefault: 'OK',
        cancelTextDefault: 'Cancel',
        isActive: false,
    }),
    watch: {
        show(val) {
            this.isActive = val;
        },
    },
    methods: {
        closeModal() {
            this.$emit('update:show', false);
        },
        handleAction(callback) {
            if (callback) {
                callback();
            }
            this.closeModal();
        },
        handlePositiveActionClick(callback) {
            if (callback) {
                callback();
            }
        },
    },
};
</script>

<style scoped lang="scss">
.TestimonialsContainer {
    background-color: var(--color-bg);
    padding: 200px 75px 200px;
    background-color: white;
    background-color: var(--color-bg);

    @media (max-width: 768px) {
        padding: 100px 24px 100px;
    }
    .TestimonialsHeading {
        h2 {
            color: var(--color-blue-dark);
            font-weight: 700;
            line-height: 1.25;
            text-align: center;
            max-width: 80%;
            margin: 0 auto 24px;
        }

        p {
            color: #1a202c;
            font-size: 1.125rem;
            text-align: center;
            margin-top: 8px;

            @media (max-width: breakpoint(tabletPortrait)) {
                font-size: 1rem;
                font-weight: 400;
            }
        }
    }

    .TestimonialsContent {
        display: grid;
        gap: 16px;
        padding-top: 32px;
        justify-content: center;
        grid-template-columns: repeat(4, 400px);

        @media (max-width: 1600px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: 1200px) {
            grid-template-columns: repeat(1, 1fr);
        }

        .TestimonialItem {
            background-color: #ffffff;
            border: 1px solid #e2e8f0;
            box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
            padding: 30px;
            border-radius: 16px;
            line-height: 1.75;
            font-size: 16px;
            display: flex;
            flex-direction: column;

            blockquote {
                color: #1a202c;
                margin: 0;
                margin-bottom: 24px;
            }

            .TestimonialAuthor {
                display: flex;
                align-items: center;
                gap: 16px;
                margin-top: auto;

                img {
                    width: 40px;
                    height: 40px;
                    border-radius: 9999px;
                    object-fit: cover;
                }

                .AuthorName {
                    font-weight: 600;
                    color: #1a202c;
                }

                .AuthorDetails {
                    color: #718096;
                }
            }
        }
    }
}
</style>
