<template>
    <router-link :to="to" class="Back-Arrow" :class="{ 'Back-Arrow--white': variant === 'white' }">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="none" d="M0 0h24v24H0z"/><path fill="currentColor" d="M7.828 11H20v2H7.828l5.364 5.364-1.414 1.414L4 12l7.778-7.778 1.414 1.414z"/></svg>
        Zurück
    </router-link>
</template>

<script>
export default {
    name: 'BackArrow',
    props: {
        variant: {
            type: String,
            default: 'white',
        },
        to: {
            type: String,
            default: '/',
        },
    },
};
</script>

<style lang="scss" scoped>
.Back-Arrow {
    position: absolute;
    display: flex;
    align-items: center;
    top: 10px;
    left: 10px;
    z-index: 1;
    transition: all 0.3s ease;
    border-radius: 8px;
    padding: 10px 15px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0);
    display: flex;
    align-items: center;
    color: black;
    font-size: 12px;
    text-decoration: none;

    &:hover {
        transform: translateX(-5px);
        background-color: rgba(255, 255, 255, 0.1);
    }

    &.Back-Arrow--white {
        color: white;
    }

    svg {
        width: 20px;
        height: 20px;
        margin-right: 5px;
    }
}
</style>
