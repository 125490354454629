var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Footer"
  }, [_c('div', {
    staticClass: "Content"
  }, [_vm._m(0), _c('nav', {
    staticClass: "Navigation"
  }, [_c('div', {
    staticClass: "Col"
  }, [_c('h3', [_vm._v("Produkt")]), _vm._m(1), _vm._m(2), _c('router-link', {
    attrs: {
      "to": "sign-in"
    }
  }, [_c('span', [_vm._v("Login")])])], 1), _vm._m(3)])]), _c('span', {
    staticClass: "Hint"
  }, [_vm._v("Copyright © TaxiFusion " + _vm._s(_vm.currentYear))])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    staticClass: "Logo",
    attrs: {
      "href": "/"
    }
  }, [_c('img', {
    attrs: {
      "src": require("../assets/svg/logo.svg"),
      "alt": ""
    }
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    attrs: {
      "href": "#features"
    }
  }, [_c('span', [_vm._v("Features")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    attrs: {
      "href": "#pricing"
    }
  }, [_c('span', [_vm._v("Preise")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Col"
  }, [_c('h3', [_vm._v("taxiFusion")]), _c('a', {
    attrs: {
      "href": "#"
    }
  }, [_c('span', [_vm._v("Über uns")])]), _c('a', {
    attrs: {
      "href": "/impressum"
    }
  }, [_c('span', [_vm._v("Impressum")])])]);
}]

export { render, staticRenderFns }