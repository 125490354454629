<template>
    <div class="FoundersWrap">
        <Card class="Founders" :class="[`Founders--${size}`]">
            <!-- <span class="Border"></span> -->
            <div class="SectionHeader">
                <h2>Unsere Vision</h2>
            </div>
            <br />
            <div class="FoundersImages">
                <div v-for="founder in founders" :key="founder.name" class="FounderCard">
                    <img :src="founder.image" :alt="founder.name" />
                    <div class="FounderInfo">
                        <h3>{{ founder.name }}</h3>
                        <!-- <p>{{ founder.role }}</p> -->
                        <p class="Responsibility">{{ founder.responsibility }}</p>
                    </div>
                </div>
            </div>
            <!-- <span class="FounderCopy"> Gründer von taxiFusion: {{ founderNames }} </span> -->
            <br />
            <div class="FoundersCopy">
                <h5>Die Zukunft der Taxiverwaltung gestalten</h5>
                <p>
                    Bei TaxiFusion setzen wir auf Technologie, um Menschen zu verbinden und Arbeit
                    zu vereinfachen. Unsere Vision: eine effiziente, benutzerfreundliche und
                    zukunftssichere Taxiverwaltung.
                </p>
                <p>
                    Inmitten der digitalen Transformation stehen wir an vorderster Front, um Ihnen
                    die besten Werkzeuge für nachhaltigen Erfolg zu bieten.
                </p>
                <br />

                <h5>Unser Ziel?</h5>
                <p>
                    Sie mit den besten Werkzeugen auszustatten, um Ihren Erfolg langfristig zu
                    sichern.
                </p>
            </div>
            <br />
            <br />
            <hr />
            <CTA style="text-align: center;">
                <h2>
                    <span>Haben Sie noch Fragen?</span>
                </h2>
                <p>
                    Wir sind für Sie da, um die beste Lösung für Ihr Taxi-Unternehmen zu finden.
                    <br />
                    Rufen Sie uns an unter:
                    <a href="tel:+493076006505"> 030 / 76 00 65 05</a>
                </p>
            </CTA>
        </Card>
    </div>
</template>

<script>
import Card from '@/components/Card.vue';
import CTA from './CTA.vue';

export default {
    components: {
        Card,
        CTA,
    },
    props: {
        size: {
            type: String,
            default: 'medium',
        },
    },
    data() {
        return {
            founders: [
                {
                    name: 'Gobie Nanthakumar',
                    role: 'UI/UX & Frontend-Experte',
                    responsibility: 'Verantwortlich für UI, Design und Frontend',
                    image: '/founders/gobie.png',
                },
                {
                    name: 'Baris Simsek',
                    role: 'CEO',
                    responsibility: 'Leitet das Unternehmen und ist verantwortlich für die Vision',
                    image: '/founders/baris.png',
                },
                {
                    name: 'Rrezart Hajdari',
                    role: 'CTO',
                    responsibility: 'Verantwortlich für die technische Infrastruktur',
                    image: '/founders/rrezart.png',
                },
            ],
        };
    },
    computed: {
        founderNames() {
            return this.founders.map(founder => founder.name).join(', ');
        },
    },
};
</script>

<style lang="scss">
.FoundersWrap {
    padding: 120px;
    background: var(--color-blue-dark);

    hr {
        margin: 20px 0;
        border: 0;
        background-color: rgba(0, 0, 0, 0.17);
        height: 1px;
        width: 100%;
    }
}
.Founders {
    position: relative;
    max-width: 1100px;
    margin: 0 auto;
    padding: 120px 0 0px;
    border-radius: 20px;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 14px 17px 38px 0 rgba(0, 0, 0, 0.17);
    padding: 50px 50px 0;

    @media (max-width: 768px) {
        padding: 50px 20px;
    }

    .Border {
        width: 100%;
        height: 10px;
        background-color: var(--color-blue-dark);
        border-radius: 40px;
    }

    > div {
        max-width: 1000px;
        margin: 0 auto;
        width: 100%;
    }

    .SectionHeader {
        margin: 30px 0 0;
        text-align: center;

        p {
            font-size: 1.125rem;
        }
    }

    .FoundersCopy {
        max-width: 750px;
        margin: 0 auto 20px;
    }
    h5 {
        font-weight: 700;
        margin: 1rem 0;
    }
    p {
        width: 100%;
        font-size: 1.25rem;
        margin: 0;
        line-height: 1.6;
    }

    .FoundersImages {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        gap: 20px;
        flex-wrap: wrap;
        margin-bottom: 20px;
    }

    .FounderCard {
        text-align: center;
        width: 180px;

        img {
            border-radius: 12px;
            width: 180px;
            height: 300px;
            object-fit: cover;
            border: solid 1px rgba(0, 0, 0, 0.15);
            box-shadow: 3px 20px 60px 0 rgba(0, 0, 0, 0.1);
            transition: transform 0.3s ease;

            &:hover {
                transform: scale(1.05);
            }
        }

        .FounderInfo {
            margin-top: 10px;
            margin-left: 12px;
            text-align: left;
            h3 {
                font-size: 1rem;
                font-weight: 600;
                margin: 0;
            }

            p {
                font-size: 0.875rem;
                color: #666;
                margin: 5px 0 0;
                line-height: 1.4;
            }

            .Responsibility {
                font-size: 0.75rem;
                color: #888;
                margin-top: 5px;
            }
        }
    }

    .FounderCopy {
        margin-top: 20px;
        display: block;
        text-align: center;
        font-size: 0.875rem;
        color: #666;
    }

    h2 {
        width: 100%;
        font-weight: 700;
        margin: 0 0 20px;
        line-height: 1.25;

        span {
            font-weight: 700;
        }
    }

    a {
        text-decoration: underline;

        @media screen and (max-width: 480px) {
            text-decoration: underline;
        }
    }
}
</style>
