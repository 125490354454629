var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "App"
  }, [_c('SiteHeader'), _c('Banner'), _c('About'), _c('Credentials'), _c('Features'), _c('Testimonials'), _c('CTA', {
    attrs: {
      "size": "large"
    }
  }, [_c('h2', [_c('span', [_vm._v("Benötigen Sie ein Taxameter?")]), _c('br'), _c('br'), _vm._v(" Nutzen Sie "), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://digitax-berlin.de/"
    }
  }, [_vm._v("Digitax")]), _vm._v(", unser Partner des Vertrauens ")]), _c('p', [_vm._v(" Wir arbeiten eng mit zertifizierten Herstellern von Fiskaltaxametern zusammen. Dadurch sind wir in der Lage Ihnen Geräte anzubieten, die speziell auf unser System abgepasst sind. Und das beste: Sie kriegen die Geräte auch gleich bei uns. "), _c('br'), _c('br'), _vm._v(" Bei Fragen rufen Sie uns einfach an. Wir beraten Sie gern! ")])]), _c('Pricing'), _c('Contact'), _c('Founders'), _c('SiteFooter')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }