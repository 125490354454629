<template>
    <div class="SiteHeader">
        <a href="/" class="Logo"><img src="../assets/svg/logo.svg"/></a>
        <nav class="Navigation">
            <a class="SiteNavigation isActive" href="#">taxifusion</a>
            <a class="SiteNavigation" href="#features">features</a>
            <a class="SiteNavigation" href="#pricing">preise</a>
            <a class="SiteNavigation" href="#contact">kontakt</a>
            <router-link class="SiteNavigation" to="register">Registrieren</router-link>
            <router-link to="sign-in">
                <SiteButton content="Login" />
            </router-link>
        </nav>
    </div>
</template>

<script>
import SiteButton from './SiteButton';

export default {
    name: 'SiteHeader',
    components: {
        SiteButton,
    },
    props: {},
    data() {
        return {};
    },
};
</script>

<style lang="scss" scoped>
.SiteHeader {
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 30px;
    @extend %sectionWrapper;
    position: relative;
    z-index: 2;

    .Logo {
        width: 70px;
    }

    .Navigation {
        .SiteNavigation {
            font-size: 16px;
            color: white;
            letter-spacing: 0.5px;
            text-transform: lowercase;
            text-decoration: none;
            position: relative;
            transition: all 0.3s ease;
            margin-right: 30px;
            font-weight: 400;
            text-shadow: 1px 1px black;

            &::before {
                display: block;
                position: absolute;
                bottom: -2px;
                left: 0;
                content: '';
                height: 2px;
                width: 100%;
                transform: scaleX(0);
                transition: all 0.3s ease;
                background-color: var(--color-white);
                transform-origin: left;
            }

            &.isActive,
            &:hover {
                color: var(--color-text-white);
                &::before {
                    transform: scaleX(1);
                }
            }
            &.isActive {
                font-weight: 400;
                color: white;
            }
        }

        @media (max-width: breakpoint(tabletLandscape)) {
            > a.SiteNavigation {
                display: none;
            }
        }
    }
}
</style>
