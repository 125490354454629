var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Card",
    class: "is-".concat(_vm.size)
  }, [_vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }