<template>
    <div class="Register">
        <BackArrow variant="white" />
        <img src="/banner.jpg" alt="" class="Banner" />

        <div class="Wrapper CustomerData">
            <div class="Header">
                <h1>Registrierung</h1>
            </div>
            <p>
                Bitte füllen Sie das Formular aus, um Ihr Unternehmen zu registrieren.
                <br />
                Wir werden uns dann mit Ihnen in Verbindung setzen.
            </p>
            <br />
            <br />

            <form @submit.prevent="submitForm" class="CustomerForm">
                <div class="FormSection">
                    <div class="InputWrap">
                        <label for="contactName">Ansprechpartner</label>
                        <input
                            class="Input"
                            type="text"
                            id="contactName"
                            v-model="form.contactName"
                            required
                        />
                        <span class="Hint">Der Name des Ansprechpartners.</span>
                    </div>
                    <div class="InputWrap">
                        <label for="owner">Inhaber</label>
                        <input class="Input" type="text" id="owner" v-model="form.owner" required />
                        <span class="Hint">Der Name des Inhabers.</span>
                    </div>
                    <div class="InputWrap">
                        <label for="birthdate">Geburtsdatum</label>
                        <input
                            class="Input"
                            type="date"
                            id="birthdate"
                            v-model="form.birthdate"
                            required
                        />
                        <span class="Hint">Das Geburtsdatum des Inhabers.</span>
                    </div>
                    <div class="InputWrap">
                        <label for="national ">Nationalität</label>
                        <input
                            class="Input"
                            type="text"
                            id="national"
                            v-model="form.national"
                            required
                        />
                        <span class="Hint">z.B. Deutsch, Türkisch, etc.</span>
                    </div>
                    <div class="InputWrap">
                        <label for="companyName">Firmenname</label>
                        <input
                            class="Input"
                            type="text"
                            id="companyName"
                            v-model="form.companyName"
                            required
                        />
                        <span class="Hint">Der offizielle Name Ihres Unternehmens.</span>
                    </div>
                    <div class="InputWrap">
                        <label for="legalForm">Rechtsform</label>
                        <select class="Input" id="legalForm" v-model="form.legalForm" required>
                            <option value="einzelunternehmen">Einzelunternehmen</option>
                            <option value="gmbh">GmbH</option>
                            <option value="gmbh">GmbH</option>
                            <option value="ug">UG</option>
                            <option value="ag">AG</option>
                            <option value="ohg">OHG</option>
                            <option value="gbr">GbR</option>
                        </select>
                        <span class="Hint">Die Rechtsform Ihres Unternehmens.</span>
                    </div>
                    <div class="FormRow">
                        <div class="InputWrap">
                            <label for="taxId">Steuernummer</label>
                            <input
                                class="Input"
                                type="text"
                                id="taxId"
                                v-model="form.taxId"
                                required
                            />
                            <span class="Hint">Ihre Steuernummer.</span>
                        </div>
                        <div class="InputWrap">
                            <label for="vatId">USt-IdNr.</label>
                            <input
                                class="Input"
                                type="text"
                                id="vatId"
                                v-model="form.vatId"
                                required
                            />
                            <span class="Hint">Ihre Umsatzsteuer-Identifikationsnummer.</span>
                        </div>
                    </div>
                    <div class="FormRow">
                        <div class="InputWrap">
                            <label for="address1">Straße</label>
                            <input
                                class="Input"
                                type="text"
                                id="address1"
                                v-model="form.address1"
                                required
                            />
                            <span class="Hint">Die Straße Ihres Firmensitzes.</span>
                        </div>

                        <div class="InputWrap">
                            <label for="address2">Zusätzliche Adresse</label>
                            <input
                                class="Input"
                                type="text"
                                id="address2"
                                v-model="form.address2"
                            />
                            <span class="Hint">Zusätzliche Adressinformationen.</span>
                        </div>
                    </div>
                    <div class="FormRow">
                        <div class="InputWrap">
                            <label for="zipCode">PLZ</label>
                            <input
                                class="Input"
                                type="text"
                                id="zipCode"
                                v-model="form.zipCode"
                                required
                            />
                            <span class="Hint">Die Postleitzahl Ihres Firmensitzes.</span>
                        </div>
                        <div class="InputWrap">
                            <label for="city">Ort</label>
                            <input
                                class="Input"
                                type="text"
                                id="city"
                                v-model="form.city"
                                required
                            />
                            <span class="Hint">Der Ort Ihres Firmensitzes.</span>
                        </div>
                    </div>

                    <div class="FormRow">
                        <div class="InputWrap">
                            <label for="email">Email</label>
                            <input
                                class="Input"
                                type="email"
                                id="email"
                                v-model="form.email"
                                required
                            />
                            <span class="Hint">Damit wir Sie kontaktieren können.</span>
                        </div>
                        <div class="InputWrap">
                            <label for="phone">Telefon</label>
                            <input
                                class="Input"
                                type="tel"
                                id="phone"
                                v-model="form.phone"
                                required
                            />
                            <span class="Hint">Damit wir Sie kontaktieren können.</span>
                        </div>
                        <div class="InputWrap">
                            <label for="fax">Fax</label>
                            <input class="Input" type="text" id="fax" v-model="form.fax" />
                            <span class="Hint">Ihre Faxnummer.</span>
                        </div>
                    </div>

                    <div class="InputWrap">
                        <label for="bankName">Bank</label>
                        <input class="Input" type="text" id="bankName" v-model="form.bankName" />
                        <span class="Hint">Der Name Ihrer Bank.</span>
                    </div>
                    <div class="FormRow">
                        <div class="InputWrap">
                            <label for="iban">IBAN</label>
                            <input class="Input" type="text" id="iban" v-model="form.iban" />
                            <span class="Hint">Ihre IBAN.</span>
                        </div>
                        <div class="InputWrap">
                            <label for="bic">BIC</label>
                            <input class="Input" type="text" id="bic" v-model="form.bic" />
                            <span class="Hint">Ihre BIC.</span>
                        </div>
                    </div>

                    <div class="InputWrap">
                        <label for="paymentTerms"> Lastschrift</label>
                        <select
                            class="Input"
                            id="paymentTerms"
                            v-model="form.paymentTerms"
                            required
                        >
                            <option value="yes">Ja</option>
                            <option value="no">Nein</option>
                        </select>
                        <span class="Hint">Möchten Sie per Lastschrift zahlen?</span>
                    </div>
                    <div class="InputWrap">
                        <label for="notes">Notizen</label>
                        <textarea class="Input" id="notes" v-model="form.notes" required></textarea>
                        <span class="Hint">Zusätzliche Notizen.</span>
                    </div>
                </div>
                <div class="FormActions">
                    <button type="submit" class="SaveButton">Speichern</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import BackArrow from '@/components/BackArrow';

export default {
    components: {
        BackArrow,
    },
    data() {
        return {
            form: {
                company: '',
                legalForm: 'gmbh',
                address1: '',
                address2: '',
                zipCode: '',
                city: '',
                contactName: '',
                email: '',
                phone: '',
                fax: '',
                owner: '',
                birthdate: '',
                taxId: '',
                vatId: '',
                nationality: '',
                bankName: '',
                iban: '',
                bic: '',
                paymentTerms: 'yes',
                notes: '',
            },
        };
    },
    methods: {
        submitForm() {
            // Handle the form submission
        },
    },
};
</script>

<style scoped lang="scss">
.Register {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    margin: 20px;
    background-color: var(--color-bg);
}

p {
    line-height: 1.75;
    margin-bottom: 0px;
}
.Banner {
    margin-top: 80px;
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 40px;

    position: absolute;
    top: -80px;
    left: 0;
    border-radius: 0;
}

h1 {
    font-weight: bold;
    margin-bottom: 40px;
}
.FormRow {
    display: flex;
    gap: 20px;

    @media (max-width: breakpoint(tabletPortrait)) {
        flex-direction: column;
    }
}

.CustomerForm {
    border-radius: 10px;
    margin: 0px auto;
}
.FormSection {
    padding: 40px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.07);
    border: 1px solid #ddd;
    display: grid;
    gap: 10px;

    @media (max-width: breakpoint(tabletPortrait)) {
        padding: 20px;
    }
}

.Wrapper.CustomerData {
    max-width: 900px;
    margin-top: 200px;
    padding: 40px 0;
}

.InputWrap {
    display: flex;
    flex-direction: column;
    gap: 5px;

    label {
        font-weight: bold;
        font-size: 14px;
    }
    .Input,
    textarea {
        width: 100%;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid #ddd;
        background: white;

        &:focus {
            outline: none;
            border-color: color(blue-light);
        }
    }
    textarea {
        min-height: 100px;
    }
}

.Hint {
    font-size: 12px;
    color: #666;
    width: 100%;
    align-self: flex-end;
    justify-self: flex-end;
    margin-left: auto;
    text-align: right;
}
.FormActions {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}
.SaveButton {
    background-color: var(--color-blue-light);
    color: white;
    border: none;
    padding: 15px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
        background-color: var(--color-blue-dark);
    }
    @media (min-width: 600px) {
        grid-column: span 2;
    }
}
</style>
