var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Register"
  }, [_c('BackArrow', {
    attrs: {
      "variant": "white"
    }
  }), _c('img', {
    staticClass: "Banner",
    attrs: {
      "src": "/banner.jpg",
      "alt": ""
    }
  }), _c('div', {
    staticClass: "Wrapper CustomerData"
  }, [_vm._m(0), _vm._m(1), _c('br'), _c('br'), _c('form', {
    staticClass: "CustomerForm",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submitForm.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "FormSection"
  }, [_c('div', {
    staticClass: "InputWrap"
  }, [_c('label', {
    attrs: {
      "for": "contactName"
    }
  }, [_vm._v("Ansprechpartner")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.contactName,
      expression: "form.contactName"
    }],
    staticClass: "Input",
    attrs: {
      "type": "text",
      "id": "contactName",
      "required": ""
    },
    domProps: {
      "value": _vm.form.contactName
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "contactName", $event.target.value);
      }
    }
  }), _c('span', {
    staticClass: "Hint"
  }, [_vm._v("Der Name des Ansprechpartners.")])]), _c('div', {
    staticClass: "InputWrap"
  }, [_c('label', {
    attrs: {
      "for": "owner"
    }
  }, [_vm._v("Inhaber")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.owner,
      expression: "form.owner"
    }],
    staticClass: "Input",
    attrs: {
      "type": "text",
      "id": "owner",
      "required": ""
    },
    domProps: {
      "value": _vm.form.owner
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "owner", $event.target.value);
      }
    }
  }), _c('span', {
    staticClass: "Hint"
  }, [_vm._v("Der Name des Inhabers.")])]), _c('div', {
    staticClass: "InputWrap"
  }, [_c('label', {
    attrs: {
      "for": "birthdate"
    }
  }, [_vm._v("Geburtsdatum")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.birthdate,
      expression: "form.birthdate"
    }],
    staticClass: "Input",
    attrs: {
      "type": "date",
      "id": "birthdate",
      "required": ""
    },
    domProps: {
      "value": _vm.form.birthdate
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "birthdate", $event.target.value);
      }
    }
  }), _c('span', {
    staticClass: "Hint"
  }, [_vm._v("Das Geburtsdatum des Inhabers.")])]), _c('div', {
    staticClass: "InputWrap"
  }, [_c('label', {
    attrs: {
      "for": "national "
    }
  }, [_vm._v("Nationalität")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.national,
      expression: "form.national"
    }],
    staticClass: "Input",
    attrs: {
      "type": "text",
      "id": "national",
      "required": ""
    },
    domProps: {
      "value": _vm.form.national
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "national", $event.target.value);
      }
    }
  }), _c('span', {
    staticClass: "Hint"
  }, [_vm._v("z.B. Deutsch, Türkisch, etc.")])]), _c('div', {
    staticClass: "InputWrap"
  }, [_c('label', {
    attrs: {
      "for": "companyName"
    }
  }, [_vm._v("Firmenname")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.companyName,
      expression: "form.companyName"
    }],
    staticClass: "Input",
    attrs: {
      "type": "text",
      "id": "companyName",
      "required": ""
    },
    domProps: {
      "value": _vm.form.companyName
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "companyName", $event.target.value);
      }
    }
  }), _c('span', {
    staticClass: "Hint"
  }, [_vm._v("Der offizielle Name Ihres Unternehmens.")])]), _c('div', {
    staticClass: "InputWrap"
  }, [_c('label', {
    attrs: {
      "for": "legalForm"
    }
  }, [_vm._v("Rechtsform")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.legalForm,
      expression: "form.legalForm"
    }],
    staticClass: "Input",
    attrs: {
      "id": "legalForm",
      "required": ""
    },
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.form, "legalForm", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "einzelunternehmen"
    }
  }, [_vm._v("Einzelunternehmen")]), _c('option', {
    attrs: {
      "value": "gmbh"
    }
  }, [_vm._v("GmbH")]), _c('option', {
    attrs: {
      "value": "gmbh"
    }
  }, [_vm._v("GmbH")]), _c('option', {
    attrs: {
      "value": "ug"
    }
  }, [_vm._v("UG")]), _c('option', {
    attrs: {
      "value": "ag"
    }
  }, [_vm._v("AG")]), _c('option', {
    attrs: {
      "value": "ohg"
    }
  }, [_vm._v("OHG")]), _c('option', {
    attrs: {
      "value": "gbr"
    }
  }, [_vm._v("GbR")])]), _c('span', {
    staticClass: "Hint"
  }, [_vm._v("Die Rechtsform Ihres Unternehmens.")])]), _c('div', {
    staticClass: "FormRow"
  }, [_c('div', {
    staticClass: "InputWrap"
  }, [_c('label', {
    attrs: {
      "for": "taxId"
    }
  }, [_vm._v("Steuernummer")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.taxId,
      expression: "form.taxId"
    }],
    staticClass: "Input",
    attrs: {
      "type": "text",
      "id": "taxId",
      "required": ""
    },
    domProps: {
      "value": _vm.form.taxId
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "taxId", $event.target.value);
      }
    }
  }), _c('span', {
    staticClass: "Hint"
  }, [_vm._v("Ihre Steuernummer.")])]), _c('div', {
    staticClass: "InputWrap"
  }, [_c('label', {
    attrs: {
      "for": "vatId"
    }
  }, [_vm._v("USt-IdNr.")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.vatId,
      expression: "form.vatId"
    }],
    staticClass: "Input",
    attrs: {
      "type": "text",
      "id": "vatId",
      "required": ""
    },
    domProps: {
      "value": _vm.form.vatId
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "vatId", $event.target.value);
      }
    }
  }), _c('span', {
    staticClass: "Hint"
  }, [_vm._v("Ihre Umsatzsteuer-Identifikationsnummer.")])])]), _c('div', {
    staticClass: "FormRow"
  }, [_c('div', {
    staticClass: "InputWrap"
  }, [_c('label', {
    attrs: {
      "for": "address1"
    }
  }, [_vm._v("Straße")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.address1,
      expression: "form.address1"
    }],
    staticClass: "Input",
    attrs: {
      "type": "text",
      "id": "address1",
      "required": ""
    },
    domProps: {
      "value": _vm.form.address1
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "address1", $event.target.value);
      }
    }
  }), _c('span', {
    staticClass: "Hint"
  }, [_vm._v("Die Straße Ihres Firmensitzes.")])]), _c('div', {
    staticClass: "InputWrap"
  }, [_c('label', {
    attrs: {
      "for": "address2"
    }
  }, [_vm._v("Zusätzliche Adresse")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.address2,
      expression: "form.address2"
    }],
    staticClass: "Input",
    attrs: {
      "type": "text",
      "id": "address2"
    },
    domProps: {
      "value": _vm.form.address2
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "address2", $event.target.value);
      }
    }
  }), _c('span', {
    staticClass: "Hint"
  }, [_vm._v("Zusätzliche Adressinformationen.")])])]), _c('div', {
    staticClass: "FormRow"
  }, [_c('div', {
    staticClass: "InputWrap"
  }, [_c('label', {
    attrs: {
      "for": "zipCode"
    }
  }, [_vm._v("PLZ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.zipCode,
      expression: "form.zipCode"
    }],
    staticClass: "Input",
    attrs: {
      "type": "text",
      "id": "zipCode",
      "required": ""
    },
    domProps: {
      "value": _vm.form.zipCode
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "zipCode", $event.target.value);
      }
    }
  }), _c('span', {
    staticClass: "Hint"
  }, [_vm._v("Die Postleitzahl Ihres Firmensitzes.")])]), _c('div', {
    staticClass: "InputWrap"
  }, [_c('label', {
    attrs: {
      "for": "city"
    }
  }, [_vm._v("Ort")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.city,
      expression: "form.city"
    }],
    staticClass: "Input",
    attrs: {
      "type": "text",
      "id": "city",
      "required": ""
    },
    domProps: {
      "value": _vm.form.city
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "city", $event.target.value);
      }
    }
  }), _c('span', {
    staticClass: "Hint"
  }, [_vm._v("Der Ort Ihres Firmensitzes.")])])]), _c('div', {
    staticClass: "FormRow"
  }, [_c('div', {
    staticClass: "InputWrap"
  }, [_c('label', {
    attrs: {
      "for": "email"
    }
  }, [_vm._v("Email")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.email,
      expression: "form.email"
    }],
    staticClass: "Input",
    attrs: {
      "type": "email",
      "id": "email",
      "required": ""
    },
    domProps: {
      "value": _vm.form.email
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "email", $event.target.value);
      }
    }
  }), _c('span', {
    staticClass: "Hint"
  }, [_vm._v("Damit wir Sie kontaktieren können.")])]), _c('div', {
    staticClass: "InputWrap"
  }, [_c('label', {
    attrs: {
      "for": "phone"
    }
  }, [_vm._v("Telefon")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.phone,
      expression: "form.phone"
    }],
    staticClass: "Input",
    attrs: {
      "type": "tel",
      "id": "phone",
      "required": ""
    },
    domProps: {
      "value": _vm.form.phone
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "phone", $event.target.value);
      }
    }
  }), _c('span', {
    staticClass: "Hint"
  }, [_vm._v("Damit wir Sie kontaktieren können.")])]), _c('div', {
    staticClass: "InputWrap"
  }, [_c('label', {
    attrs: {
      "for": "fax"
    }
  }, [_vm._v("Fax")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.fax,
      expression: "form.fax"
    }],
    staticClass: "Input",
    attrs: {
      "type": "text",
      "id": "fax"
    },
    domProps: {
      "value": _vm.form.fax
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "fax", $event.target.value);
      }
    }
  }), _c('span', {
    staticClass: "Hint"
  }, [_vm._v("Ihre Faxnummer.")])])]), _c('div', {
    staticClass: "InputWrap"
  }, [_c('label', {
    attrs: {
      "for": "bankName"
    }
  }, [_vm._v("Bank")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.bankName,
      expression: "form.bankName"
    }],
    staticClass: "Input",
    attrs: {
      "type": "text",
      "id": "bankName"
    },
    domProps: {
      "value": _vm.form.bankName
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "bankName", $event.target.value);
      }
    }
  }), _c('span', {
    staticClass: "Hint"
  }, [_vm._v("Der Name Ihrer Bank.")])]), _c('div', {
    staticClass: "FormRow"
  }, [_c('div', {
    staticClass: "InputWrap"
  }, [_c('label', {
    attrs: {
      "for": "iban"
    }
  }, [_vm._v("IBAN")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.iban,
      expression: "form.iban"
    }],
    staticClass: "Input",
    attrs: {
      "type": "text",
      "id": "iban"
    },
    domProps: {
      "value": _vm.form.iban
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "iban", $event.target.value);
      }
    }
  }), _c('span', {
    staticClass: "Hint"
  }, [_vm._v("Ihre IBAN.")])]), _c('div', {
    staticClass: "InputWrap"
  }, [_c('label', {
    attrs: {
      "for": "bic"
    }
  }, [_vm._v("BIC")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.bic,
      expression: "form.bic"
    }],
    staticClass: "Input",
    attrs: {
      "type": "text",
      "id": "bic"
    },
    domProps: {
      "value": _vm.form.bic
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "bic", $event.target.value);
      }
    }
  }), _c('span', {
    staticClass: "Hint"
  }, [_vm._v("Ihre BIC.")])])]), _c('div', {
    staticClass: "InputWrap"
  }, [_c('label', {
    attrs: {
      "for": "paymentTerms"
    }
  }, [_vm._v(" Lastschrift")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.paymentTerms,
      expression: "form.paymentTerms"
    }],
    staticClass: "Input",
    attrs: {
      "id": "paymentTerms",
      "required": ""
    },
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.form, "paymentTerms", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "yes"
    }
  }, [_vm._v("Ja")]), _c('option', {
    attrs: {
      "value": "no"
    }
  }, [_vm._v("Nein")])]), _c('span', {
    staticClass: "Hint"
  }, [_vm._v("Möchten Sie per Lastschrift zahlen?")])]), _c('div', {
    staticClass: "InputWrap"
  }, [_c('label', {
    attrs: {
      "for": "notes"
    }
  }, [_vm._v("Notizen")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.notes,
      expression: "form.notes"
    }],
    staticClass: "Input",
    attrs: {
      "id": "notes",
      "required": ""
    },
    domProps: {
      "value": _vm.form.notes
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "notes", $event.target.value);
      }
    }
  }), _c('span', {
    staticClass: "Hint"
  }, [_vm._v("Zusätzliche Notizen.")])])]), _vm._m(2)])])], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Header"
  }, [_c('h1', [_vm._v("Registrierung")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_vm._v(" Bitte füllen Sie das Formular aus, um Ihr Unternehmen zu registrieren. "), _c('br'), _vm._v(" Wir werden uns dann mit Ihnen in Verbindung setzen. ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "FormActions"
  }, [_c('button', {
    staticClass: "SaveButton",
    attrs: {
      "type": "submit"
    }
  }, [_vm._v("Speichern")])]);
}]

export { render, staticRenderFns }