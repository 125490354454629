<template>
    <div class="SiteButton" @click="$emit('onClick')">
        <span>{{ content }}</span>
    </div>
</template>

<script>
export default {
    name: 'SiteButton',
    props: {
        content: {
            default: '',
        },
    },
    data() {
        return {};
    },
};
</script>

<style lang="scss" scoped>
.SiteButton {
    position: relative;
    border-radius: 3px;
    padding: 13px 45px;
    box-sizing: border-box;
    display: inline-block;
    background-image: linear-gradient(75deg, color(yellow-start), color(yellow-end));
    box-shadow: 0 3px 15px 0 rgba(black, 0.15);
    transition: all 0.3s cubic-bezier(0.41, 0.06, 0.61, 0.98);
    transform: translateZ(0);
    cursor: pointer;

    &:hover {
        transform: translateY(-5px) translateZ(0);
        background-position: 0 -100%;
        box-shadow: 0 10px 30px 0 rgba(black, 0.2);
    }
    span {
        font-weight: 700;
        color: var(--color-text-black);
        text-transform: uppercase;
        font-size: 1em;
    }
}
</style>
