<template lang="html">
    <div class="Credentials">
        <div class="Col">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M12 1l8.217 1.826a1 1 0 01.783.976v9.987a6 6 0 01-2.672 4.992L12 23l-6.328-4.219A6 6 0 013 13.79V3.802a1 1 0 01.783-.976L12 1zm4.452 7.222l-4.95 4.949-2.828-2.828-1.414 1.414L11.503 16l6.364-6.364-1.415-1.414z"/></svg>
            <h3>Staatlich Anerkannt</h3>
            <p>
                Wir erfüllen stets die aktuellen Voraussetzungen der wichtigsten Behörden der
                Taxibranche und kooperieren nur mit Herstellern zusammen, welche die neusten
                Anforderungen ebenfalls erfüllen können!
                <br />
                <br />

                <a
                    target="_blank"
                    style="text-decoration: underline;"
                    rel="noopener noreferrer"
                    href="https://www.ihk.de/berlin/produktmarken/branchen/verkehr/brancheninformationen/fragen-und-antworten-zum-fiskaltaxameter-2272198"
                >
                    Informationen zum INSIKA-"Fiskaltaxameter"
                </a>
            </p>
        </div>
        <div class="Col">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.005 20h-4v2h-2v-2h-1a1 1 0 01-1-1V4a1 1 0 011-1h7V1.59a.5.5 0 01.582-.493L21.17 2.86a1 1 0 01.836.987V6h1v2h-1v7h1v2h-1v2.152a1 1 0 01-.836.987l-1.164.194V22h-2v-1.334l-7.418 1.237a.5.5 0 01-.582-.494V20zm2-.361l8-1.334V4.694l-8-1.333v16.278zm4.5-5.64c-.828 0-1.5-1.119-1.5-2.5 0-1.38.671-2.5 1.5-2.5.828 0 1.5 1.12 1.5 2.5 0 1.381-.672 2.5-1.5 2.5z"/></svg>
            <h3>Aktuelle Sicherheit | TSE Lösung</h3>
            <p>
                Seit 2017 sind wir an Ihrer Seite und bieten Ihnen eine entsprechende Lösung zur
                Datenspeicherung für Ihr Fiskaltaxameter an. Ab dem 01. Januar 2024 wird die
                technische Sicherheitseinrichtung (TSE) für Taxameter und Wegstreckenzähler
                eingeführt.
                <br /><br />
                <a
                    target="_blank"
                    style="text-decoration: underline;"
                    rel="noopener noreferrer"
                    href="#contact"
                >
                    Kontaktieren Sie uns für Ihre entsprechende Lösung!
                </a>
            </p>
        </div>
        <div class="Col">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M17 7a8.003 8.003 0 00-7.493 5.19l1.873.703A6.002 6.002 0 0123 15a6 6 0 01-6 6H7A6 6 0 015.008 9.339a7 7 0 0113.757-2.143A8.027 8.027 0 0017 7z"/></svg>
            <h3>Jederzeit und überall auf Ihr Geschäft zugreifen</h3>
            <p>
                Unsere webbasierte Software ermöglicht es Ihnen, Ihr Unternehmen von überall aus zu
                verwalten. Auf dem PC, Smartphone oder Tablet.
            </p>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.Credentials {
    @extend %sectionWrapper;
    display: grid;
    flex-direction: row;
    justify-content: space-between;
    grid-template-columns: 1fr;
    gap: 120px;
    padding: 120px 40px;

    @media (max-width: breakpoint(tabletPortrait)) {
        grid-template-columns: 1fr;
        gap: 0px;
        padding: 60px 20px;
    }

    .Col {
        flex: 1;
        line-height: 40px;
        max-width: 1200px;
        margin: 0 auto;

        svg {
            width: 50px;
            height: 50px;
            margin-left: 10px;
            fill: var(--color-blue-dark);
        }

        h3 {
            padding-top: 20px;
            margin: 0;
            font-weight: 700;
            padding-bottom: 5px;
        }
        p {
            color: color((text-black));
        }
    }
}
</style>
