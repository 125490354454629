var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "FoundersWrap"
  }, [_c('Card', {
    staticClass: "Founders",
    class: ["Founders--".concat(_vm.size)]
  }, [_c('div', {
    staticClass: "SectionHeader"
  }, [_c('h2', [_vm._v("Unsere Vision")])]), _c('br'), _c('div', {
    staticClass: "FoundersImages"
  }, _vm._l(_vm.founders, function (founder) {
    return _c('div', {
      key: founder.name,
      staticClass: "FounderCard"
    }, [_c('img', {
      attrs: {
        "src": founder.image,
        "alt": founder.name
      }
    }), _c('div', {
      staticClass: "FounderInfo"
    }, [_c('h3', [_vm._v(_vm._s(founder.name))]), _c('p', {
      staticClass: "Responsibility"
    }, [_vm._v(_vm._s(founder.responsibility))])])]);
  }), 0), _c('br'), _c('div', {
    staticClass: "FoundersCopy"
  }, [_c('h5', [_vm._v("Die Zukunft der Taxiverwaltung gestalten")]), _c('p', [_vm._v(" Bei TaxiFusion setzen wir auf Technologie, um Menschen zu verbinden und Arbeit zu vereinfachen. Unsere Vision: eine effiziente, benutzerfreundliche und zukunftssichere Taxiverwaltung. ")]), _c('p', [_vm._v(" Inmitten der digitalen Transformation stehen wir an vorderster Front, um Ihnen die besten Werkzeuge für nachhaltigen Erfolg zu bieten. ")]), _c('br'), _c('h5', [_vm._v("Unser Ziel?")]), _c('p', [_vm._v(" Sie mit den besten Werkzeugen auszustatten, um Ihren Erfolg langfristig zu sichern. ")])]), _c('br'), _c('br'), _c('hr'), _c('CTA', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_c('h2', [_c('span', [_vm._v("Haben Sie noch Fragen?")])]), _c('p', [_vm._v(" Wir sind für Sie da, um die beste Lösung für Ihr Taxi-Unternehmen zu finden. "), _c('br'), _vm._v(" Rufen Sie uns an unter: "), _c('a', {
    attrs: {
      "href": "tel:+493076006505"
    }
  }, [_vm._v(" 030 / 76 00 65 05")])])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }