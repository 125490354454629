<template lang="html">
    <div class="About">
        <h2>Warum TaxiFusion?</h2>
        <p>
            <b>taxiFusion</b> ist mehr als nur eine Verpflichtung zur Datenspeicherung.
            <br />
            <br />
            Unser Ziel ist es, Ihnen die Werkzeuge an die Hand zu geben, die Sie benötigen, um Ihr
            Geschäft effizienter und rentabler zu gestalten. Mit unserer benutzerfreundlichen
            Software sparen Sie wertvolle Zeit und Ressourcen, die Sie in den Ausbau Ihres
            Unternehmens investieren können.
            <br />
            <br />
            Es geht um die effiziente Verwaltung Ihres ganzen Unternehmens mit einer einzigen
            benutzerfreundlichen Plattform.
            <br />
        </p>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.About {
    @extend %sectionWrapper;
    background-color: var(--color-white);
    box-shadow: 14px 17px 38px 0 rgba(0, 0, 0, 0.17);
    border-radius: 10px;
    padding: 78px 75px 85px;
    max-width: 1200px;
    position: relative;
    z-index: 1;

    @media (max-width: breakpoint(tabletPortrait)) {
        padding: 50px 20px 50px;
        margin: 10px;
        width: calc(100% - 20px);
    }

    h2 {
        margin: 0;
        padding-bottom: 30px;
        padding-top: 0px;
        font-weight: 700;
    }

    p {
        color: color((text-black));
    }
}
</style>
