var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Spinner",
    class: [{
      'is-small': _vm.size === 'small'
    }, {
      'is-medium': _vm.size === 'medium'
    }, {
      'is-large': _vm.size === 'large'
    }]
  }, [_c('IconSpinner')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }